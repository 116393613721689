import React from "react";
import { Card, Tag } from "antd";
import { Call, CallStatus } from "../interfaces";
import { formatDistanceToNow } from "date-fns";
import { de } from "date-fns/locale";

interface CallCardProps {
  call: Call;
  selectedCall: boolean;
  onClick: () => void;
}

const statusToColor = (status: CallStatus) => {
  switch (status) {
    case CallStatus.INITIATED:
      return "geekblue";
    case CallStatus.RINGING:
      return "orange";
    case CallStatus.IN_PROGRESS:
      return "yellow";
    case CallStatus.COMPLETED:
      return "green";
    case CallStatus.MAILBOX:
      return "volcano";
    case CallStatus.NOT_REACHED:
      return "volcano";
    case CallStatus.ERROR:
      return "red";
    default:
      return "gray";
  }
};

const statusToCopy = (status: CallStatus) => {
  switch (status) {
    case CallStatus.INITIATED:
      return "Gestarted";
    case CallStatus.RINGING:
      return "Klingelt";
    case CallStatus.IN_PROGRESS:
      return "Im gange";
    case CallStatus.COMPLETED:
      return "Abgeschlossen";
    case CallStatus.MAILBOX:
      return "Anrufbeantworter";
    case CallStatus.NOT_REACHED:
      return "Nicht erreicht";
    case CallStatus.ERROR:
      return "Fehler";
    default:
      return "Unbekannt";
  }
};
const CallCard: React.FC<CallCardProps> = ({ call, selectedCall, onClick }) => {
  return (
    <Card
      hoverable
      onClick={onClick}
      style={{
        margin: 6,
        marginBottom: 16,
        border: selectedCall ? "2px solid #1890ff" : "none",
      }}
    >
      <p>
        <strong>{call.to}</strong>
      </p>
      <p>
        vor {formatDistanceToNow(new Date(call.createdAt), { locale: de })}{" "}
        {"•"}{" "}
        <Tag color={statusToColor(call.status)}>
          {statusToCopy(call.status)}
        </Tag>
      </p>
    </Card>
  );
};

export default CallCard;
