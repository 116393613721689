import { useRouteError, isRouteErrorResponse, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { Result, Button } from "antd";

export default function ErrorPage() {
  const error = useRouteError();
  const navigate = useNavigate();

  useEffect(() => {
    if (isRouteErrorResponse(error) && error.status === 403) {
      navigate("/login");
    }
  }, [error, navigate]);

  console.error(JSON.stringify(error));

  return (
    <div style={{ padding: "20px", textAlign: "center" }}>
      <Result
        status="error"
        title="Fehler!"
        subTitle="Entschuldigung, ein unerwarteter Fehler ist aufgetreten."
        extra={
          <p>
            <i>
              {isRouteErrorResponse(error)
                ? `Fehlercode: ${error.status} - ${error.data}`
                : "Unbekannter Fehler"}
            </i>
          </p>
        }
      />
      <Button type="primary" onClick={() => navigate("/")}>
        Zurück zur Startseite
      </Button>
    </div>
  );
}
