import React from "react";
import { useLoaderData } from "react-router-dom";
import { Layout, Card, Row, Col, Table } from "antd";
import { backendUrl } from "../util";

const { Content } = Layout;

export async function loader() {
  const response = await fetch(`${backendUrl}/get-daily-call-stats`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("authToken")}`,
    },
  });

  if (response.status === 403) {
    throw new Response("Unauthorized", { status: 403 });
  }

  if (response.ok) {
    const data = await response.json();
    return { data };
  } else {
    throw new Response("Error fetching daily call stats", {
      status: response.status,
    });
  }
}

interface LoaderData {
  data: {
    allTime: {
      totalCalls: number;
      totalCallsByStatus: Record<string, number>;
      totalMinutes: number;
      longestCallInMinutes: number;
    };
    daily: Record<
      string,
      {
        totalCalls: number;
        totalCallsByStatus: Record<string, number>;
        totalMinutes: number;
        longestCallInMinutes: number;
      }
    >;
  };
}

const callStatuses = [
  "INITIATED",
  "RINGING",
  "IN_PROGRESS",
  "COMPLETED",
  "MAILBOX",
  "NOT_REACHED",
  "ERROR",
];

const generateColumns = (statuses: string[]) => {
  const baseColumns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Total Calls",
      dataIndex: "totalCalls",
      key: "totalCalls",
    },
    {
      title: "Total Minutes",
      dataIndex: "totalMinutes",
      key: "totalMinutes",
    },
    {
      title: "Longest Call (Minutes)",
      dataIndex: "longestCallInMinutes",
      key: "longestCallInMinutes",
    },
  ];

  const statusColumns = statuses.map((status) => ({
    title: `${status}`,
    dataIndex: ["totalCallsByStatus", status],
    key: `${status.toLowerCase()}`,
    render: (text: any, record: any) => record.totalCallsByStatus[status] || 0,
  }));

  return [...baseColumns, ...statusColumns];
};

const Dashboard: React.FC = () => {
  const { data } = useLoaderData() as LoaderData;

  const allTimeStats = data.allTime;
  const dailyStats = Object.entries(data.daily).map(([date, stats]) => ({
    date,
    ...stats,
  }));

  const columns = generateColumns(callStatuses);

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Content
        style={{
          padding: "20px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Row gutter={[16, 16]} style={{ width: "100%", marginBottom: "20px" }}>
          <Col span={4}>
            <Card title="Total Calls" bordered={false}>
              {allTimeStats.totalCalls}
            </Card>
          </Col>
          <Col span={4}>
            <Card title="Total Minutes" bordered={false}>
              {allTimeStats.totalMinutes}
            </Card>
          </Col>
          <Col span={4}>
            <Card title="Longest Call (Minutes)" bordered={false}>
              {allTimeStats.longestCallInMinutes}
            </Card>
          </Col>
          {callStatuses.map((status) => (
            <Col span={4} key={status}>
              <Card title={`${status}`} bordered={false}>
                {allTimeStats.totalCallsByStatus[status] || 0}
              </Card>
            </Col>
          ))}
        </Row>
        <div style={{ width: "100%", overflowY: "auto" }}>
          <Table
            dataSource={dailyStats}
            columns={columns}
            rowKey="date"
            pagination={false}
            scroll={{ y: 300 }}
          />
        </div>
      </Content>
    </Layout>
  );
};

export default Dashboard;
