import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Card,
  message,
  Space,
  Switch,
  TimePicker,
} from "antd";
import {
  useLoaderData,
  useNavigate,
  ActionFunctionArgs,
} from "react-router-dom";
import { backendUrl } from "../util";
import dayjs from "dayjs";

export async function loader() {
  const response = await fetch(`${backendUrl}/get-account-details`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("authToken")}`,
    },
  });

  if (response.status === 403) {
    throw new Response("Unauthorized", { status: response.status });
  }

  if (response.ok) {
    const settings = await response.json();
    return { settings };
  } else {
    throw new Response("Failed to fetch account settings", {
      status: response.status,
    });
  }
}

export async function action({ request }: ActionFunctionArgs) {
  const formData = await request.formData();

  const reqData = {
    accountName: formData.get("accountName"),
    defaultAgentId: formData.get("defaultAgentId"),
    calendlyApiKey: formData.get("calendlyApiKey"),
    calendlyExternalURL: formData.get("calendlyExternalURL"),
    calendlyEventType: formData.get("calendlyEventType"),
    transferNumber: formData.get("transferNumber"),
    callQueue: formData.getAll("callQueue").map((value) => Number(value)),
    accountEmail: formData.get("accountEmail"),
    autoDialerEnabled: formData.get("autoDialerEnabled") === "true",
    autoDialerTimeWindow: {
      monday: {
        start:
          (formData.get("autoDialerTimeWindow.monday.start") as string) || "",
        end: (formData.get("autoDialerTimeWindow.monday.end") as string) || "",
      },
      tuesday: {
        start:
          (formData.get("autoDialerTimeWindow.tuesday.start") as string) || "",
        end: (formData.get("autoDialerTimeWindow.tuesday.end") as string) || "",
      },
      wednesday: {
        start:
          (formData.get("autoDialerTimeWindow.wednesday.start") as string) ||
          "",
        end:
          (formData.get("autoDialerTimeWindow.wednesday.end") as string) || "",
      },
      thursday: {
        start:
          (formData.get("autoDialerTimeWindow.thursday.start") as string) || "",
        end:
          (formData.get("autoDialerTimeWindow.thursday.end") as string) || "",
      },
      friday: {
        start:
          (formData.get("autoDialerTimeWindow.friday.start") as string) || "",
        end: (formData.get("autoDialerTimeWindow.friday.end") as string) || "",
      },
      saturday: {
        start:
          (formData.get("autoDialerTimeWindow.saturday.start") as string) || "",
        end:
          (formData.get("autoDialerTimeWindow.saturday.end") as string) || "",
      },
      sunday: {
        start:
          (formData.get("autoDialerTimeWindow.sunday.start") as string) || "",
        end: (formData.get("autoDialerTimeWindow.sunday.end") as string) || "",
      },
    },
  };

  try {
    const response = await fetch(`${backendUrl}/update-account-settings`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      },
      body: JSON.stringify(reqData),
    });

    if (!response.ok) {
      return { error: response.status };
    }

    return { success: true };
  } catch (error) {
    console.error("Error updating account settings:", error);
    message.error(
      "Fehler beim Aktualisieren der Kontoeinstellungen. Bitte versuchen Sie es erneut."
    );
    return { error: error };
  }
}

interface LoaderData {
  settings: {
    calendlyApiKey?: string;
    calendlyExternalURL?: string;
    calendlyEventType?: string;
    accountName?: string;
    defaultAgentId?: string;
    transferNumber?: string;
    callQueue?: number[];
    accountEmail?: string;
    autoDialerEnabled?: boolean;
    autoDialerTimeWindow?: {
      monday: { start: string; end: string };
      tuesday: { start: string; end: string };
      wednesday: { start: string; end: string };
      thursday: { start: string; end: string };
      friday: { start: string; end: string };
      saturday: { start: string; end: string };
      sunday: { start: string; end: string };
    };
  };
  error?: number;
}

const AccountSettings: React.FC = () => {
  const { settings, error } = useLoaderData() as LoaderData;
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    if (error === 403) {
      navigate("/login");
    }
  }, [error, navigate]);

  const transformedSettings = {
    ...settings,
    autoDialerTimeWindow: Object.fromEntries(
      Object.entries(settings.autoDialerTimeWindow || {}).map(
        ([day, times]) => [
          day,
          {
            start: times.start ? dayjs(times.start, "HH:mm") : null,
            end: times.end ? dayjs(times.end, "HH:mm") : null,
          },
        ]
      )
    ),
  };

  const handleValuesChange = () => {
    setIsEditing(true);
  };

  const handleCancel = () => {
    form.resetFields();
    setIsEditing(false);
  };

  const handleSave = async () => {
    try {
      const values = await form.validateFields();
      const formatTime = (time) => (time ? dayjs(time).format("HH:mm") : "");

      const reqData = {
        ...values,
        autoDialerTimeWindow: Object.fromEntries(
          Object.entries(values.autoDialerTimeWindow || {}).map(
            ([day, times]) => [
              day,
              {
                start: formatTime(
                  (times as { start: string; end: string }).start
                ),
                end: formatTime((times as { start: string; end: string }).end),
              },
            ]
          )
        ),
      };

      const response = await fetch(`${backendUrl}/update-account-settings`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
        body: JSON.stringify(reqData),
      });

      if (response.ok) {
        message.success("Kontoeinstellungen erfolgreich aktualisiert");
        setIsEditing(false);
      } else {
        message.error("Fehler beim Aktualisieren der Kontoeinstellungen");
      }
    } catch (error) {
      console.error("Error saving account settings:", error);
      message.error(
        "Fehler beim Speichern der Kontoeinstellungen. Bitte versuchen Sie es erneut."
      );
    }
  };

  return (
    <Row style={{ height: "100%", overflowY: "auto" }}>
      <Col span={12} offset={6} style={{ padding: "10px" }}>
        <Card title="Kontoeinstellungen">
          <Form
            form={form}
            layout="vertical"
            initialValues={transformedSettings}
            onValuesChange={handleValuesChange}
          >
            <Form.Item label="Account ID" name="id">
              <Input readOnly />
            </Form.Item>
            <Form.Item label="Kontoname" name="accountName">
              <Input />
            </Form.Item>
            <Form.Item label="Standard-Agenten-ID" name="defaultAgentId">
              <Input />
            </Form.Item>
            <Form.Item label="Telefonnummer" name="phoneNumber">
              <Input readOnly />
            </Form.Item>
            <Form.Item label="Transfernummer" name="transferNumber">
              <Input />
            </Form.Item>
            <Form.Item label="Account Email" name="accountEmail">
              <Input />
            </Form.Item>
            <Form.Item label="Calendly API-Key" name="calendlyApiKey">
              <Input />
            </Form.Item>
            <Form.Item label="Calendly external URL" name="calendlyExternalURL">
              <Input />
            </Form.Item>
            <Form.Item label="Calendly Event Type ID" name="calendlyEventType">
              <Input />
            </Form.Item>

            <Form.Item label="Call Queue (first value gets ignored, leave 0)">
              <Form.List name="callQueue">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, fieldKey, ...restField }) => (
                      <Space
                        key={key}
                        style={{ display: "flex", marginBottom: 8 }}
                        align="baseline"
                      >
                        <Form.Item
                          {...restField}
                          name={[name]}
                          rules={[
                            {
                              required: true,
                              message: "Please input the time in minutes",
                            },
                          ]}
                        >
                          <Input
                            placeholder="Time in minutes"
                            type="number"
                            style={{ width: "60%" }}
                          />
                        </Form.Item>
                        <Button type="link" onClick={() => remove(name)}>
                          Remove
                        </Button>
                      </Space>
                    ))}
                    <Form.Item>
                      <Button
                        type="dashed"
                        onClick={() => add()}
                        block
                        style={{ width: "60%" }}
                      >
                        Add Call Queue Time
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </Form.Item>

            <Form.Item
              label="Auto Dialer Enabled"
              name="autoDialerEnabled"
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>

            {[
              "monday",
              "tuesday",
              "wednesday",
              "thursday",
              "friday",
              "saturday",
              "sunday",
            ].map((day) => (
              <Card
                key={day}
                title={day.charAt(0).toUpperCase() + day.slice(1)}
              >
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      label="Start Time (in UTC)"
                      name={["autoDialerTimeWindow", day, "start"]}
                    >
                      <TimePicker format="HH:mm" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label="End Time (in UTC)"
                      name={["autoDialerTimeWindow", day, "end"]}
                    >
                      <TimePicker format="HH:mm" />
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
            ))}

            <Form.Item>
              <Button
                style={{ marginRight: 8 }}
                onClick={handleCancel}
                disabled={!isEditing}
              >
                Abbrechen
              </Button>
              <Button type="primary" onClick={handleSave} disabled={!isEditing}>
                Speichern
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Col>
    </Row>
  );
};

export default AccountSettings;
