import React from "react";
import { Modal, Input, Button, message, Form, Checkbox } from "antd";
import { useNavigate, useFetcher } from "react-router-dom";

interface AddContactModalProps {
  visible: boolean;
  onCancel: () => void;
}

const AddContactModal: React.FC<AddContactModalProps> = ({
  visible,
  onCancel,
}) => {
  const [form] = Form.useForm();
  const fetcher = useFetcher();

  const handleFinish = (values: any) => {
    fetcher.submit(values, { method: "post", action: "/contacts" });
    form.resetFields();
    onCancel();
  };

  return (
    <Modal title="Add Contact" open={visible} onCancel={onCancel} footer={null}>
      <Form onFinish={handleFinish} form={form}>
        <Form.Item
          name="firstName"
          label="First Name"
          rules={[{ required: true, message: "Please enter the first name" }]}
        >
          <Input name="firstName" />
        </Form.Item>
        <Form.Item
          name="lastName"
          label="Last Name"
          rules={[{ required: true, message: "Please enter the last name" }]}
        >
          <Input name="lastName" />
        </Form.Item>
        <Form.Item
          name="email"
          label="Email"
          rules={[{ required: true, message: "Please enter the email" }]}
        >
          <Input name="email" />
        </Form.Item>
        <Form.Item
          name="phoneNumber"
          label="Phone Number"
          rules={[{ required: true, message: "Please enter the phone number" }]}
        >
          <Input name="phoneNumber" />
        </Form.Item>
        <Form.Item name="addToAutoDialer" valuePropName="checked">
          <Checkbox>Add to Auto Dialer</Checkbox>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Add Contact
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddContactModal;
