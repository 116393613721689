import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Input, Button, Card, message } from "antd";
import { backendUrl } from "../util";

const Login: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const handleLogin = async (values: {
    accountId: string;
    password: string;
  }) => {
    setLoading(true);
    try {
      const response = await fetch(`${backendUrl}/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ accountId: values.accountId, password: values.password }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Login failed");
      }

      const data = await response.json();
      const authToken = data.token; // Assuming the token is in the 'token' field of the response

      // Store the auth token in localStorage
      localStorage.setItem("authToken", authToken);

      navigate("/"); // Redirect to home page after successful login
    } catch (error) {
      console.error("Login failed", error);

      message.error("Login failed. Please check your account ID and password.");

      form.setFields([
        { name: "accountId", errors: [" "] }, // Clear any previous errors
        { name: "password", errors: [" "] }, // Clear any previous errors
      ]);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <Card title="Login" style={{ width: 300 }}>
        <Form form={form} onFinish={handleLogin}>
          <Form.Item
            name="accountId"
            rules={[
              { required: true, message: "Please input your account ID!" },
            ]}
          >
            <Input placeholder="Account ID" />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[{ required: true, message: "Please input your password!" }]}
          >
            <Input.Password placeholder="Password" />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={loading} block>
              Login
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default Login;