import React, { useState, useEffect } from "react";
import {
  Space,
  Table,
  Tag,
  Button,
  Modal,
  message,
  Form,
  Input,
  Row,
  Col,
  Spin,
  Card,
  Flex,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { Outlet, useNavigate, useLoaderData, NavLink } from "react-router-dom";
import { Call } from "../interfaces";
import { formatDistanceToNow } from "date-fns";
import { de } from "date-fns/locale";
import CallCard from "../components/callCard";
import { backendUrl } from "../util";

export async function loader() {
  const response = await fetch(`${backendUrl}/get-all-calls`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("authToken")}`,
    },
  });

  if (response.status === 403) {
    throw new Response("Unauthorized", { status: 403 });
  }

  if (response.ok) {
    const calls = await response.json();
    const callsWithKey = calls.map((call: Call) => ({
      ...call,
      key: call.id,
    }));
    return { calls: callsWithKey };
  } else {
    throw new Response("Error fetching calls", { status: response.status });
  }
}

interface LoaderData {
  calls: Call[];
  error?: number;
}

const Calls: React.FC = () => {
  const { calls, error } = useLoaderData() as LoaderData;
  const navigate = useNavigate();
  const [selectedCallId, setSelectedCallId] = useState<string | null>(null);

  const handleCallClick = (callId: string) => {
    setSelectedCallId(callId);
    navigate(`/calls/${callId}`);
  };

  return (
    <Row style={{ height: "100%" }}>
      <Col
        span={5}
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "10px",
          height: "95%",
        }}
      >
        <div style={{ flex: 1, overflowY: "auto" }}>
          {calls.map((call) => (
            <NavLink to={`/calls/${call.id}`} key={call.id}>
              {({ isActive, isPending }) => (
                <CallCard
                  key={call.id}
                  call={call}
                  selectedCall={
                    isPending ||
                    call.id === selectedCallId ||
                    (!selectedCallId && isActive)
                  }
                  onClick={() => handleCallClick(call.id)}
                />
              )}
            </NavLink>
          ))}
        </div>
      </Col>
      <Col span={19} style={{ padding: "10px" }}>
        <Outlet />
      </Col>
    </Row>
  );
};

export default Calls;
