import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  SettingOutlined,
  UserOutlined,
  PhoneOutlined,
  LogoutOutlined,
  AreaChartOutlined,
} from "@ant-design/icons";
import { Button, Layout, Menu, Modal, theme } from "antd";
import { Outlet } from "react-router-dom";

const { Header, Sider, Content } = Layout;

const Home: React.FC = () => {
  const [collapsed, setCollapsed] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  useEffect(() => {
    const authToken = localStorage.getItem("authToken");
    if (!authToken) navigate("/login");
  }, [navigate]);

  const handleMenuClick = (key: string) => {
    if (key === "logout") {
      Modal.confirm({
        title: "Abmelden",
        content: "Sind Sie sicher, dass Sie sich abmelden möchten?",
        okText: "Ja",
        cancelText: "Nein",
        centered: true,
        onOk: () => {
          localStorage.removeItem("authToken");
          navigate("/login");
        },
      });
    } else {
      navigate(`/${key}`);
    }
  };

  const getSelectedKey = () => {
    if (location.pathname.startsWith("/dashboard")) return "dashboard";
    if (location.pathname.startsWith("/contacts")) return "contacts";
    if (location.pathname.startsWith("/calls")) return "calls";
    if (location.pathname.startsWith("/settings")) return "settings";
    return "";
  };

  return (
    <Layout>
      <Sider trigger={null} collapsible collapsed={collapsed}>
        <div className="logo">
          <img
            className="logo-img"
            src="/telli_home.png"
            alt="telli Home Logo"
          />
        </div>
        <Menu
          theme="dark"
          mode="inline"
          selectedKeys={[getSelectedKey()]}
          onClick={({ key }) => handleMenuClick(key)}
        >
          <Menu.Item key="dashboard" icon={<AreaChartOutlined />}>
            Dashboard
          </Menu.Item>
          <Menu.Item key="contacts" icon={<UserOutlined />}>
            Kunden
          </Menu.Item>
          <Menu.Item key="calls" icon={<PhoneOutlined />}>
            Anrufe
          </Menu.Item>
          <Menu.Item key="settings" icon={<SettingOutlined />}>
            Einstellungen
          </Menu.Item>
          <Menu.Item key="logout" icon={<LogoutOutlined />}>
            Abmelden
          </Menu.Item>
        </Menu>
      </Sider>
      <Layout>
        <Header style={{ padding: 0, background: colorBgContainer }}>
          <Button
            type="text"
            icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            onClick={() => setCollapsed(!collapsed)}
            style={{
              fontSize: "16px",
              width: 64,
              height: 64,
            }}
          />
        </Header>
        <Content>
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
};

export default Home;
