export interface Contact {
  id: string;
  firstName: string;
  lastName: string;
  createdAt: number;
  key: React.Key;
  timeAgo: string;
  accountId: string;
  calls: Call[];
  email: string;
  phoneNumber: string;
  inCall: boolean;
  reached: boolean;
  externalId?: string;
}

export interface ContactDetail {
  id: string;
  firstName: string;
  lastName: string;
  createdAt: number;
  timeAgo: string;
  accountId: string;
  email: string;
  phoneNumber: string;
  inCall: boolean;
  reached: boolean;
  retellLlmDynamicVariables?: any;
  attempts?: number;
  addToAutoDialer?: boolean;
  calls: Call[];
}

export interface Call {
  id: string;
  createdAt: number;
  status: CallStatus;
  contactId: string;
  accountId: string;
  [key: string]: any; // For additional dynamic fields
}

export enum CallStatus {
  RINGING = "RINGING",
  INITIATED = "INITIATED",
  IN_PROGRESS = "IN_PROGRESS",
  COMPLETED = "COMPLETED",
  MAILBOX = "MAILBOX",
  NOT_REACHED = "NOT_REACHED",
  ERROR = "ERROR",
}

export interface ContactEnrichedCall {
  id: string;
  createdAt: number;
  status: string;
  contactId: string;
  accountId: string;
  contact: Contact;
  [key: string]: any; // For additional dynamic fields
}

export interface Agent {
  id: string;
  prompt: string;
  retellAgentId: string;
}
