import React from "react";
import { Card, Tag } from "antd";
import { Contact } from "../interfaces";

interface ContactCardProps {
  contact: Contact;
  selectedContact: boolean;
  onClick: () => void;
}

const ContactCard: React.FC<ContactCardProps> = ({
  contact,
  selectedContact,
  onClick,
}) => {
  const getStatusTag = () => {
    if (contact.inCall) {
      return <Tag color="yellow">In Call</Tag>;
    } else if (!contact.reached) {
      return <Tag color="blue">Not Reached</Tag>;
    } else {
      return <Tag color="green">Reached</Tag>;
    }
  };

  return (
    <Card
      hoverable
      onClick={onClick}
      style={{
        margin: 6,
        marginBottom: 16,
        border: selectedContact ? "2px solid #1890ff" : "none",
      }}
    >
      <p>
        <strong>
          {contact.firstName} {contact.lastName}
        </strong>
      </p>
      <p>{getStatusTag()}</p>
    </Card>
  );
};

export default ContactCard;