import React, { useState, useEffect } from "react";
import {
  Space,
  Table,
  Tag,
  Button,
  Modal,
  message,
  Form,
  Input,
  Row,
  Col,
  Spin,
  Card,
  Flex,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import {
  Outlet,
  useNavigate,
  useLoaderData,
  ActionFunctionArgs,
  redirect,
  NavLink,
} from "react-router-dom";
import { Contact } from "../interfaces";
import { formatDistanceToNow } from "date-fns";
import { de } from "date-fns/locale";
import ContactCard from "../components/contactCard";
import AddContactModal from "../components/addContactModal";
import { backendUrl } from "../util";

export async function loader() {
  const response = await fetch(`${backendUrl}/get-contacts`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("authToken")}`,
    },
  });

  if (response.status === 403) {
    throw new Response("Unauthorized", { status: 403 });
  }

  if (response.ok) {
    const contacts = await response.json();
    const contactsWithTimeAgo = contacts.map((contact: Contact) => ({
      ...contact,
      key: contact.id,
      timeAgo: `vor ${formatDistanceToNow(new Date(contact.createdAt), {
        locale: de,
      })}`,
    }));
    contactsWithTimeAgo.sort(
      (a: Contact, b: Contact) => b.createdAt - a.createdAt
    );
    return { contacts: contactsWithTimeAgo };
  } else {
    throw new Response("Error fetching contacts", { status: response.status });
  }
}

export async function action({ request, params }: ActionFunctionArgs) {
  const formData = await request.formData();
  console.log("request:", request);
  console.log("formData:", formData);
  console.log("params:", params);
  const reqData = {
    firstName: formData.get("firstName"),
    lastName: formData.get("lastName"),
    email: formData.get("email"),
    phoneNumber: formData.get("phoneNumber"),
    addToAutoDialer: formData.get("addToAutoDialer") === "true"
  };

  try {
    const response = await fetch(`${backendUrl}/add-contact`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      },
      body: JSON.stringify(reqData),
    });

    if (!response.ok) {
      return { error: response.status };
    }

    const newContact = await response.json();
    return redirect(`/contacts/${newContact.id}`);
  } catch (error) {
    console.error("Error adding contact:", error);
    message.error("Error adding contact. Please try again.");
    return { error: error };
  }
}

interface LoaderData {
  contacts: Contact[];
  error?: number;
}

const Contacts: React.FC = () => {
  const { contacts, error } = useLoaderData() as LoaderData;
  const navigate = useNavigate(); // Add this line
  const [isModalVisible, setIsModalVisible] = useState(false); // State to manage modal visibility
  const [selectedContactId, setSelectedContactId] = useState<string | null>(null); // State to track selected contact;

  const handleContactClick = (contactId: string) => {
    setSelectedContactId(contactId); // Update selected contact
    navigate(`/contacts/${contactId}`);
  };

  const handleAddContactClick = () => {
    // Function to open the modal
    setIsModalVisible(true);
    setSelectedContactId(null);
  };

  const handleCancel = () => {
    // Function to close the modal
    setIsModalVisible(false);
  };


  return (
    <Row style={{ height: "100vh" }}>
      <Col
        span={4}
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "10px",
          height: "95%",
        }}
      >
        <Card
          hoverable
          style={{ marginBottom: 16 }}
          onClick={handleAddContactClick}
        >
          <Flex>
            <PlusOutlined style={{ fontSize: "24px", paddingRight: 12 }} />
            Add Contact
          </Flex>
        </Card>
        <div style={{ flex: 1, overflowY: "auto" }}>
          <Space direction="vertical" size="small" style={{ width: "100%" }}>
            {contacts.map((contact) => (
              <NavLink to={`/contacts/${contact.id}`} key={contact.id}>
                {({ isActive, isPending }) => (
                  <ContactCard
                    contact={contact}
                    selectedContact={
                      isPending ||
                      contact.id === selectedContactId ||
                      (!selectedContactId && isActive)
                    }
                    onClick={() => handleContactClick(contact.id)}
                  />
                )}
              </NavLink>
            ))}
          </Space>
        </div>
      </Col>
      <Col span={20} style={{ padding: "10px" }}>
        <Outlet />
      </Col>
      <AddContactModal visible={isModalVisible} onCancel={handleCancel} />
    </Row>
  );
};

export default Contacts;