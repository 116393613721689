import React, { useState, useEffect } from "react";
import { Space, Card, Typography, Tabs, Skeleton } from "antd";
import { ContactEnrichedCall } from "../interfaces";
import {
  useLoaderData,
  LoaderFunctionArgs,
  useLocation,
  Link,
  useNavigation,
} from "react-router-dom";
import { backendUrl } from "../util";

function processTranscript(text: string | undefined) {
  if (typeof text === "string") {
    const parts = text.split(/(User:|Kunde:|Agent:)/g);
    return parts.map((part, index) => {
      if (part === "User:") {
        return (
          <React.Fragment key={index}>
            <br />
            <strong>Kunde:</strong>
          </React.Fragment>
        );
      } else if (part === "Kunde:" || part === "Agent:") {
        return (
          <React.Fragment key={index}>
            <br />
            <strong>{part}</strong>
          </React.Fragment>
        );
      } else {
        return part;
      }
    });
  }
  return "";
}

export async function loader({ params }: LoaderFunctionArgs) {
  const response = await fetch(
    `${backendUrl}/get-call-detail?callId=${params.callId}`,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      },
    }
  );

  if (response.status === 403) {
    throw new Response("Unauthorized", { status: response.status });
  }

  if (!response.ok) {
    throw new Response("Failed to fetch call details", {
      status: response.status,
    });
  }

  const contactEnrichedCall: ContactEnrichedCall = await response.json();
  return { contactEnrichedCall };
}

interface LoaderData {
  contactEnrichedCall: ContactEnrichedCall;
  error?: number;
}

const { TabPane } = Tabs;

const CallDetails: React.FC = () => {
  const { contactEnrichedCall } = useLoaderData() as LoaderData;
  const navigation = useNavigation();
  return (
    <Card title="Anruf Details">
      {navigation.state === "loading" ? (
        <>
          <Skeleton.Avatar active size="large" shape="circle" />
          <Skeleton active paragraph={{ rows: 1 }} />
          <Skeleton active paragraph={{ rows: 1 }} />
          <Skeleton.Button active shape="square" />
          <Skeleton active paragraph={{ rows: 4 }} />
        </>
      ) : (
        <>
          <Space direction="vertical">
            <Typography.Text>
              <strong>Kunde:</strong>
              <Link to={`/contacts/${contactEnrichedCall.contact.id}`}>
                {" "}
                {contactEnrichedCall.contact.firstName}{" "}
                {contactEnrichedCall.contact.lastName}
              </Link>
            </Typography.Text>
            <Typography.Text>
              <strong>Telefonnummer:</strong>{" "}
              {contactEnrichedCall.contact.phoneNumber}
            </Typography.Text>
          </Space>
          <Tabs defaultActiveKey="1" style={{ maxHeight: "90vh" }}>
            <TabPane tab="Transkript" key="1">
              <p
                style={{
                  height: "65vh",
                  overflowY: "auto",
                  whiteSpace: "pre-wrap",
                }}
              >
                {processTranscript(contactEnrichedCall.callDetail.transcript)}
              </p>
            </TabPane>
            <TabPane tab="JSON" key="2">
              <pre style={{ height: "65vh", overflowY: "auto" }}>
                {JSON.stringify(contactEnrichedCall, null, 2)}
              </pre>
            </TabPane>
            <TabPane tab="Recording" key="3">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <audio
                  controls
                  style={{
                    width: "80%",
                    padding: "10px",
                    border: "1px solid #d9d9d9",
                    borderRadius: "8px",
                  }}
                >
                  <source
                    src={contactEnrichedCall.callDetail.recording_url}
                    type="audio/wav"
                  />
                  Your browser does not support the audio element.
                </audio>
              </div>
            </TabPane>
            <TabPane tab="Call Analysis" key="4">
              <pre style={{ height: "65vh", overflowY: "auto" }}>
                {JSON.stringify(
                  contactEnrichedCall.callAnalysis || {},
                  null,
                  2
                )}
              </pre>
            </TabPane>
          </Tabs>
        </>
      )}
    </Card>
  );
};

export default CallDetails;
