import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider, Navigate } from "react-router-dom";
import Home from "./routes/home";
import Contacts, {
  loader as contactsLoader,
  action as contactsAction,
} from "./routes/contacts";
import Calls, { loader as callsLoader } from "./routes/calls";
import ContactDetails, {
  loader as contactDetailsLoader,
} from "./routes/contactDetails";
import CallDetails, { loader as callDetailLoader } from "./routes/callDetails";
import AccountSettings, {
  loader as accountSettingsLoader,
} from "./routes/accountSettings";
import Login from "./routes/login";
import ErrorPage from "./components/errorPage";
import Dashboard, { loader as dashboardLoader } from "./routes/dashboard";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
    children: [
      {
        index: true,
        element: <Navigate to="/dashboard" replace />,
      },
      {
        path: "dashboard/",
        loader: dashboardLoader,
        element: <Dashboard />,
        errorElement: <ErrorPage />,
      },
      {
        path: "contacts/",
        loader: contactsLoader,
        action: contactsAction,
        element: <Contacts />,
        errorElement: <ErrorPage />,
        children: [
          {
            path: ":contactId",
            loader: contactDetailsLoader,
            element: <ContactDetails />,
            errorElement: <ErrorPage />,
          },
        ],
      },
      {
        path: "calls/",
        loader: callsLoader,
        element: <Calls />,
        errorElement: <ErrorPage />,
        children: [
          {
            path: ":callId",
            loader: callDetailLoader,
            element: <CallDetails />,
            errorElement: <ErrorPage />,
          },
        ],
      },
      {
        path: "settings/",
        loader: accountSettingsLoader,
        element: <AccountSettings />,
        errorElement: <ErrorPage />,
      },
    ],
  },
  {
    path: "/login",
    element: <Login />,
  },
]);

ReactDOM.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();