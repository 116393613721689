import React, { useState, useEffect } from "react";
import { Modal, Table, Button, message, Space, Card, Skeleton } from "antd";
import { Contact, Call, ContactDetail } from "../interfaces";
import { formatDistanceToNow } from "date-fns";
import { de } from "date-fns/locale";
import {
  useLoaderData,
  LoaderFunctionArgs,
  useLocation,
  useNavigate,
  useNavigation,
} from "react-router-dom";
import { backendUrl } from "../util";
import ReactJson from "react-json-view";

export async function loader({ params }: LoaderFunctionArgs) {
  const response = await fetch(
    `${backendUrl}/get-contact-detail?contactId=${params.contactId}`,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      },
    }
  );

  if (response.status === 403) {
    throw new Response("Unauthorized", {
      status: response.status,
    });
  }

  if (!response.ok) {
    throw new Response("Failed to fetch contact details", {
      status: response.status,
    });
  }

  const contact: ContactDetail = await response.json();
  return { contact };
}

interface LoaderData {
  contact: ContactDetail;
  error?: number;
}

const defaultStyles = {
  padding: "10px",
  borderRadius: "5px",
  backgroundColor: "#f5f5f5",
  boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
};

const ContactDetails: React.FC = () => {
  const { contact } = useLoaderData() as LoaderData;
  const navigate = useNavigate();
  const navigation = useNavigation();
  const [dynamicVariables, setDynamicVariables] = useState(
    contact.retellLlmDynamicVariables || {}
  );
  const [originalVariables, setOriginalVariables] = useState(
    contact.retellLlmDynamicVariables || {}
  );
  const [isChanged, setIsChanged] = useState(false);

  useEffect(() => {
    setDynamicVariables(contact.retellLlmDynamicVariables || {});
    setOriginalVariables(contact.retellLlmDynamicVariables || {});
    setIsChanged(false);
  }, [contact]);

  const handleEdit = (edit: any) => {
    setDynamicVariables(edit.updated_src);
    setIsChanged(true);
  };

  const handleSave = () => {
    const updatedData = {
      retellLlmDynamicVariables: dynamicVariables,
    };
    fetch(`${backendUrl}/update-contact`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      },
      body: JSON.stringify({ contactId: contact.id, updates: updatedData }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to update contact");
        }
        message.success("Contact updated successfully");
        setOriginalVariables(dynamicVariables);
        setIsChanged(false);
      })
      .catch((error) => {
        console.error("Error updating contact:", error);
        message.error("Error updating contact");
      });
  };

  const initiateCall = async () => {
    try {
      const response = await fetch(`${backendUrl}/call`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
        body: JSON.stringify({ contactId: contact.id }),
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(errorText);
      }
      message.success("Call initiated successfully");
    } catch (error) {
      console.error("Error initiating call:", error);
      message.error(`Error initiating call: ${(error as Error).message}`);
    }
  };

  return (
    <Card title="Kunden Details">
      {navigation.state === "loading" ? (
        <>
          <Skeleton.Avatar active size="large" shape="circle" />
          <Skeleton active paragraph={{ rows: 1 }} />
          <Skeleton active paragraph={{ rows: 1 }} />
          <Skeleton.Button active shape="square" />
          <Skeleton active paragraph={{ rows: 4 }} />
        </>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            paddingLeft: 24,
            paddingRight: 248,
          }}
        >
          <div>
            <p>
              <strong>ID:</strong> {contact.id}
            </p>
            <p>
              <strong>External ID:</strong> {contact.externalId}
            </p>
            <p>
              <strong>First Name:</strong> {contact.firstName}
            </p>
            <p>
              <strong>Last Name:</strong> {contact.lastName}
            </p>
            <p>
              <strong>Email:</strong> {contact.email}
            </p>
            <p>
              <strong>Phone Number:</strong> {contact.phoneNumber}
            </p>
            <p>
              <strong>Attempts:</strong> {contact.attempts}
            </p>
            <p>
              <strong>In Call:</strong> {contact.inCall ? "Yes" : "No"}
            </p>
            <p>
              <strong>Reached:</strong> {contact.reached ? "Yes" : "No"}
            </p>
            <p>
              <strong>Add to Auto Dialer:</strong>{" "}
              {contact.addToAutoDialer ? "Yes" : "No"}
            </p>
            <Button
              type="primary"
              onClick={initiateCall}
              style={{ marginBottom: "24px" }}
              disabled={contact.inCall}
            >
              Initiate Call
            </Button>
          </div>

          <div style={{ maxWidth: "40%", marginLeft: "24px" }}>
            <h3>Dynamic Variables</h3>
            <ReactJson
              src={dynamicVariables}
              onEdit={handleEdit}
              onAdd={handleEdit}
              onDelete={handleEdit}
              style={defaultStyles}
            />
            <Button
              type="primary"
              onClick={handleSave}
              style={{ marginTop: "16px" }}
              disabled={!isChanged}
            >
              Save
            </Button>
          </div>
        </div>
      )}
      <div style={{ maxHeight: "60vh", overflowY: "auto", marginTop: "24px" }}>
        <Table
          dataSource={contact.calls}
          rowKey="id"
          pagination={false}
          onRow={(record) => ({
            onClick: () => navigate(`/calls/${record.id}`),
          })}
        >
          <Table.Column title="Call ID" dataIndex="id" key="id" />
          <Table.Column
            title="Created At"
            dataIndex="createdAt"
            key="createdAt"
            render={(text: number) =>
              formatDistanceToNow(new Date(text), { locale: de })
            }
          />
        </Table>
      </div>
    </Card>
  );
};

export default ContactDetails;
